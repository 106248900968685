import React from "react";

import TopBanner from "../top_banner/platform_top_banner_2";
import GetLatest from "../latest/latest";
import FeaturedSingleBlog from "../featured/featuredSingle";
import Exam from "../exam/exam";
import SingleReview from "../reviews/singleReview";
import Subscribe2 from "../subscribe/subscribe_2";
import { useTranslation } from 'react-i18next';

const PlatformCandidate = () => {
  const {t} = useTranslation();
  const exams = [
    {
      title: `${t('5_star_support_service')}`,
      description:
        `${t('our_support_service_is_available_24_in_more')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg",
    },
    {
      title: `${t('no_software_required')}`,
      description:
        `${t('none_of_our_solutions_require_downloading')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/candidate2.svg",
    },
    {
      title: `${t('accessible_everywhere_all_the_time')}`,
      description:
        `${t('with_mereos_go_beyond_geographical')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/girl_with_laptop_in_lap.svg",
    },
    {
      title: `${t('low_flow')}`,
      description:
        `${t('mereos_operates_at_a_throughput_of_256')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/candidate4.svg",
    },
  ];

  const LatestData = {
    title1: `${t('discover_our')}`,
    title2: `${t('last_publication')}`,
    description: `${t('in_partnership_with_edhec')}`,
    btnText: `${t('to_consult')}`,
  };

  return (
    <>
      <div className={`conatiner font-sans`}>
        <TopBanner
          pageTitle={t('priority_to_candidates')}
          title={t('no_software_no_hassle')}
          description={
            t('do_you_want_to_take_a_test_and_you_are_required')
          }
          imageSrc={"https://d2lxkizvrhu4im.cloudfront.net/images/man_sitting_on_way_with_Laptop.svg"}
        />

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-10/12 `}>
          <div className="my-20">
            <Exam
              title={t('a_solution_designed_for_candidates')}
              Data={exams}
              page={"candidate"}
            />
          </div>
          <div className="md:my-30">
            <GetLatest page={"platform_candidate"} data={LatestData} />
          </div>
        </div>

        <div className="relative">
          <div className="absolute bg-yellow-25 w-full  md:-bottom-1  md:h-12 z-0"></div>
          <SingleReview
            imgSrc="https://d2lxkizvrhu4im.cloudfront.net/images/Siarhei.png"
            title={t('meet_our_experts')}
            description={t('when_implementing_any_interface_build_at_mereos')}
            reviewer="Siarhei Sukach"
            reviewer_designation={t('senior_product_designer_at_mereos')}
          />
        </div>

        <div className="bg-yellow-25">
          <Subscribe2 />
        </div>
      </div>
    </>
  );
};

export default PlatformCandidate;
