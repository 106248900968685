import * as React from "react";

import PlatformCandidateComponent from "../components/platform/platform_candidate";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const PlatformCandidate = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title="Candidate friendly"
        description={t('offer_your_candidates_a_unique_experience_they_remember')}
      />
      <Layout>
        <PlatformCandidateComponent />
      </Layout>
    </>
  )
}

export default PlatformCandidate
